<template>
  <Content :route="'/integracoes'" :action="action" title="Lista de Sistemas" @event="goTo">
  <Interface
    noBtn
    :noTitle="true"
    :sortOptions="[{value: 'key', text: 'Nome'}, {value: 'description', text: 'Descrição'}, {value: 'type', text: 'Categoria'}]"
    @sort="sort"
    @filter="filterSwitch"
  >
   <Table :filter-data="filterData" :is-filtering="isFiltering" :isLoading="isLoading" :module="routeModule" :items="items" :fields="fields" :edit="edit" :sortBy="sortBy" :sortDirection="sortDirection"></Table>
  </Interface>
</Content>
</template>

<script>
import Interface from '@/components/Dashboard/Interface'
import Content from '../components/content/index'
import Table from '../components/Dashboard/Table.vue'

export default {
  components: {
    Interface, Content, Table
  },
  data: () => ({
    sortBy: undefined,
    sortDirection: true,
    edit: null,
    isFiltering: false,
    filterData: {},
    fields: [
      { key: 'key', label: 'Nome' },
      { key: 'description', label: 'Descrição' },
      { key: 'auth_type', label: 'Autenticação' },
      { key: 'base_url', label: 'URL' },
      { key: 'GoToRotas', label: 'Rotas' },
      { key: 'GoToModulosHome', label: 'Módulos' },
      { key: 'Editar', label: 'Editar' },
      { key: 'Excluir', label: 'Excluir' },
      { key: 'Refresh', label: 'Testar' },
      { key: 'Settings', label: 'Configurar' }
    ]

  }),
  watch: {
    filterData: {
      handler (value) {
        Object.keys(value).forEach((key) => {
          if (value[key] === '') {
            // console.log("empty: " + value);
            delete value[key]
          }
        })
      },
      deep: true
    }
  },
  computed: {
    action () {
      return { options: { label: 'Novo', icon: 'add' } }
    },
    items () {
      if (this.$store.state.sistemas.items.length !== 0) {
        const item = Object.entries(this.$store.state.sistemas.items.groups).map(a => a[1])
        const extraFields = item.flatMap(e => e.extra_fields)
        item.base_url = extraFields.map(a => a.value[0])
        item.forEach(i => {
          i.base_url = i.extra_fields.map(a => a.value[0])[1]
          i.auth_type = i.extra_fields.map(a => a.value[0])[0]
        })
        return item
      } else {
        return this.$store.state.sistemas.items
      }
    },
    isLoading () {
      return this.$store.state.sistemas.loading || null
    },
    routeModule () {
      return this.$store.state.sistemas
    }
  },
  created () {
    this.$store.dispatch('sistemas/init', { self: this })
  },
  methods: {

    filterSwitch (value) {
      this.isFiltering = value
      if (!value) this.filterData = {}
    },
    goTo () {
      this.$router.push({ name: 'Cadastro', params: { module: this.$store.state.sistemas.title } })
    },
    sort (e, z) {
      this.sortBy = e
      this.sortDirection = z
    }
  }
}
</script>
